ion-alert {
  --backdrop-opacity: 50% !important;
}

ion-card-content {
  padding: 0px !important;
}

ion-datetime {
  color: var(--ion-color-dark);
}