.inputlogin {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 1px solid rgb(199, 198, 198);
  border-radius: 6px;
  --padding-start: 4px !important;
}

input:-internal-autofill-selected {
  background-color: rgb(204, 255, 36) !important;
  background-image: none !important;
  color: -internal-light-dark-color(black, white) !important;
}

.info {
  --width: 95%;
}

/*
.info div.popover-content {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}
*/

.popover {
  --backdrop-opacity: 50%;
  --min-width: 50%;
}

.popover ion-card-content {
  width: calc(100% - 10px);
  left: 5px;
  display: block;
  position: relative;
}

@media only screen and (max-width: 450px) {
  img.logo {
    display: none;
  }
}

img.logo {
  max-height: 64px;
  min-width: 25px;
}